<template>
  <XDropdown
    data-testid="headerUserDropdown"
    @focus="menuOpen = true"
    @blur="menuOpen = false"
  >
    <template #activator="{ on }">
      <XButton
        design="text"
        data-testid="profileMenu"
        :style="buttonStyle"
        @click="on"
      >
        <XIcon
          icon="user-circle"
          size="lg"
        />
        <XIcon
          v-show="showPendingDocumentsDottedAlert"
          icon="circle"
          icon-style="fas"
          :class="$style.notificationIcon"
        />
        <span :class="$style.userName">{{ computedUser.name }}</span>
        <XIcon
          :icon="menuOpen ? 'chevron-up' : 'chevron-down'"
          right
        />
      </XButton>
    </template>
    <XCard
      :class="$style.dropdown"
      elevation="medium"
      padding="0px"
    >
      <Component
        :is="option.url ? 'a' : 'div'"
        v-for="option in computedOptions"
        :key="option.name"
        :data-testid="option.name"
        :class="option.url ? $style.link : $style.section"
        :href="option.url"
      >
        <XListItem
          v-if="!option.accounts"
          v-bind="option"
          :data-testid="option.dataTestid"
          :height="getHeight(option)"
          @click="handleAction(option)"
        >
          <div :class="$style.itemText">
            <XIcon
              v-if="option.hasPendingDocuments"
              icon="circle"
              icon-style="fas"
              :class="$style.notifyIcon"
            />
            <span
              v-if="option.isAccount"
              :class="$style.bullet"
            >
              {{ option.text.charAt(0) }}
            </span>
            <span :class="$style.text">{{ option.text }}</span>
            <!-- TODO: Remove Xbadge component when badge beta is inserted on XListItem -->
            <XBadge
              v-if="option.isBeta"
              :class="$style.betaBadge"
              color="brand-primary"
              icon="gem"
              size="sm"
            >
              {{ $t('general.feature.beta') }}
            </XBadge>
          </div>
        </XListItem>
        <div
          v-else
          :class="$style.accountsContainer"
          data-testid="accountsContainer"
        >
          <a
            v-for="account of option.accounts"
            :key="account.name"
            :class="$style.dropdownLink"
            :href="account.url"
          >
            <XListItem
              v-bind="account"
              :data-testid="account.dataTestid"
              :height="getHeight(account)"
              @click="handleAction(account)"
            >
              <div :class="$style.itemText">
                <span :class="$style.bullet">
                  {{ account.text.charAt(0) }}
                </span>
                <span :class="$style.text">{{ account.text }}</span>
              </div>
            </XListItem>
          </a>
        </div>
      </Component>
    </XCard>
  </XDropdown>
</template>

<script>
import { defineComponent, computed, ref } from 'vue';
import { isEmpty } from 'lodash';

import { useStore } from '@/store';
import { useI18n } from '@/locales';
import useGA from '@base/hooks/useGA';
import { useFetch } from '@base/hooks';
import { useVerifyLocation } from '@account/composables';
import { logoutCleanup } from '@operators/utils/logout-cleanup';

export default defineComponent({
  name: 'TheHeaderUserActions',
  props: {
    hideAccountOption: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    profile: {
      type: String,
      default: 'operator',
    },
  },
  emits: ['logout'],
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const { isNationalUser } = useVerifyLocation();

    const computedUser = computed(() =>
      isEmpty(props.user)
        ? store.getters['info/getAccount'].user || store.getters['info/getUsers']
        : props.user
    );

    const computedLinks = computed(() =>
      isEmpty(computedUser.value?.links?.signerArea)
        ? store.getters['info/getLinks']
        : computedUser.value.links
    );

    const pendingDocumentsLinks = computed(() => computedLinks.value?.pendingDocumentsPresence);
    const { data } = pendingDocumentsLinks.value
      ? useFetch(computedLinks.value?.pendingDocumentsPresence)
      : {};
    const hasPendingDocuments = computed(() =>
      pendingDocumentsLinks.value ? data?.value?.pendingDocumentsPresence : false
    );

    const showPendingDocumentsDottedAlert = computed(
      () => hasPendingDocuments.value && isNationalUser.value
    );

    const headerOptions = computed(() => [
      {
        text: t('layout.header.dropdown.signerArea'),
        name: 'signerArea',
        separator: true,
        isBeta: true,
        dataTestid: 'signerAreaTitle',
        condition: computedUser.value.signerAreaEnabled,
      },
      {
        text: t('layout.header.dropdown.signerDashboard'),
        name: 'signerDashboard',
        level: 1,
        icon: 'signature',
        hasPendingDocuments: hasPendingDocuments.value,
        dataTestid: 'signerAreaLink',
        action: 'signerArea',
        url: computedLinks.value.signerArea,
        condition: computedUser.value.signerAreaEnabled,
      },
      {
        text: t('layout.header.dropdown.accounts'),
        name: 'accounts',
        separator: true,
        dataTestid: 'accountsTitle',
      },
    ]);

    const headerConfigOptions = computed(() => [
      {
        text: t('layout.header.dropdown.createAccount'),
        name: 'createAccount',
        icon: 'user-plus',
        action: 'createAccount',
        level: 1,
        dataTestid: 'createAccountButton',
        condition: !computedUser.value?.accounts?.length,
      },
      {
        text: '',
        name: 'configSeparator',
        separator: true,
        noText: true,
      },
      {
        text: t('layout.header.dropdown.config'),
        name: 'config',
        icon: 'user-cog',
        action: 'config',
        dataTestid: 'operatorsLink',
      },
      {
        text: t('base.help'),
        name: 'help',
        icon: 'question-circle',
        action: 'help',
        dataTestid: 'helpButton',
      },
      {
        text: t('layout.header.dropdown.logout'),
        name: 'logout',
        icon: 'sign-out',
        action: 'signOut',
        dataTestid: 'logoutButton',
      },
    ]);

    const computedOptions = computed(() => {
      if (!isEmpty(props.options)) return props.options;

      const accountsData =
        computedUser.value?.accounts && !props.hideAccountOption
          ? computedUser.value?.accounts
          : [];
      const accountsOptions = accountsData.reduce(
        (acc, account) => {
          acc.accounts = [
            ...acc.accounts,
            {
              ...account,
              text: account.name,
              level: 1,
              dataTestid: 'accountLink',
            },
          ];
          return acc;
        },
        { accounts: [], name: 'accountsList' }
      );

      const headerConfigOptionsToShow = headerConfigOptions.value.filter(
        ({ condition }) => condition === undefined || condition
      );

      const headerOptionsToShow = headerOptions.value.filter(
        ({ condition }) => condition === undefined || condition
      );

      let options = [...headerOptionsToShow, accountsOptions, ...headerConfigOptionsToShow].filter(
        (item) => !item.accounts || item?.accounts?.length
      );

      options = !props.hideAccountOption
        ? options
        : options.filter((item) => item.name !== 'createAccount' && item.name !== 'accounts');

      return options;
    });

    const dispatchGA = (eventAction) => {
      useGA('event', eventAction, 'menu-app');
    };

    const openConfigProfile = () => {
      dispatchGA('buttonProfileConfigurationClick');

      if (props.profile === 'operator') {
        window.location.href = computedLinks.value.operatorPath;
      } else {
        window.location.href = computedLinks.value.signerProfilePath;
      }
    };

    const openSignerArea = () => {
      dispatchGA('signer-area-click');
      window.location.href = computedLinks.value.signerArea;
    };

    const goToHelp = () => {
      dispatchGA('buttonHelpCenterClick');
      window.open(t('links.help.host'), '_blank');
    };

    const logout = () => {
      dispatchGA('buttonLogoutClick');
      emit('logout');
      logoutCleanup();
      window.location.href = computedLinks.value.signOut;
    };
    const createAccountAction = () => {
      dispatchGA('buttonLogoutClick');
      window.location.href = computedLinks.value?.onboardingPath;
    };

    const handleAction = ({ action }) => {
      const itemActions = {
        signerArea: () => openSignerArea(),
        config: () => openConfigProfile(),
        help: () => goToHelp(),
        signOut: () => logout(),
        createAccount: () => createAccountAction(),
      };
      if (action in itemActions) itemActions[action]();
    };

    /* TODO: remove when button type toggle is implemented on tavola
    // ISSUE: https://github.com/clicksign/excalibur/issues/444
    */
    const menuOpen = ref(false);
    const buttonStyle = computed(() =>
      menuOpen.value
        ? { outline: '2px solid var(--color-brand-support-01-500)' }
        : { outline: 'none' }
    );

    const getHeight = (item) => (item.noText ? '1.6rem' : '4rem');

    return {
      computedOptions,
      computedUser,
      menuOpen,
      buttonStyle,
      handleAction,
      getHeight,
      hasPendingDocuments,
      showPendingDocumentsDottedAlert,
    };
  },
});
</script>

<style lang="scss" module>
.userName {
  display: none;
}

.dropdown {
  min-width: 296px;

  .link,
  .section {
    text-decoration: none;
    color: inherit;
  }
}

.itemText {
  display: flex;
  align-items: center;
}

.betaBadge {
  position: absolute;
  right: var(--space-small-x);
}

.bullet {
  --size: 17px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  margin-right: var(--space-small-xx);

  // TODO: Review this font size

  // ISSUE: https://github.com/clicksign/excalibur/issues/843
  font-size: 9px;
  font-weight: var(--font-weight-bold);
  border: var(--border-width-small) solid currentcolor;
  border-radius: var(--border-radius-small);
}

.text {
  display: block;
  overflow: hidden;
  max-width: 242px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notificationIcon {
  position: relative;
  top: -5px;
  right: 6px;

  // TODO: Review this font size

  // ISSUE: https://github.com/clicksign/excalibur/issues/843
  font-size: 8px;
  color: var(--color-feedback-error-400);
}

.notifyIcon {
  position: relative;
  top: -7px;
  right: var(--space-regular);

  // TODO: Review this font size

  // ISSUE: https://github.com/clicksign/excalibur/issues/843
  font-size: 8px;
  color: var(--color-feedback-error-400);
}

@include breakpoint('extra-large') {
  .userName {
    display: block;
    overflow: hidden;
    max-width: 128px;
    padding-left: var(--space-small-xx);
    text-overflow: ellipsis;
    line-height: var(--line-height-regular);
    white-space: nowrap;
  }
}

.accountsContainer {
  overflow-y: auto;
  max-height: 300px;
}
</style>
